import styled from 'styled-components'
import { Button, Space, Typography } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'

export const StyledSpace = styled(Space)`
  width: 100%;
  padding: 9px 0;
  border-bottom: 1px solid rgba(67, 4, 59, 0.2);
  justify-content: center;
`

export const StyledTextDelivery = styled(Text)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #43043b;
`

export const StyledText = styled(Button)`
  font-family: Lato;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #43043b;
  height: 24px;
  padding: 0;
`
export const StyledArrow = styled(ArrowRightOutlined)`
  color: #43043b;
  padding-left: 11px;
  padding-right: 11px;
`

export const AddressInfoTopBarWithoutAddress = styled(Space)`
  background-image: url('/assets/images/home-banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 48px 0;

  @media (max-width: 767px) {
    padding: 48px 24px;
    text-align: center;
  }
`

export const AddressInfoCategoryTitle = styled(Typography.Title)``

export const AddressInfoCategorySmallDescription = styled(Typography.Title)``

export const StyledBtn = styled(Button)`
  height: 48px !important;
  padding-left: 15px;
  padding-right: 15px;
`
