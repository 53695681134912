import { useCart } from '@/contexts/cart'
import { useClient } from '@/contexts/ClientContext'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import { ArrowRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Space } from 'antd'
import { useState } from 'react'
import { MapsPlacesAutocomplete } from '../MapsPlacesAutocomplete'
import {
  StyledText,
  StyledSpace,
  StyledArrow,
  StyledTextDelivery,
  AddressInfoTopBarWithoutAddress,
  AddressInfoCategoryTitle,
  AddressInfoCategorySmallDescription,
  StyledBtn,
} from './styles'

interface AddressInfoTopBar {
  categoryTitle?: string
}

const AddressInfoTopBar = ({ categoryTitle }: AddressInfoTopBar) => {
  const { address, changeAddressModal, showChangeAddressModal } = useClient()
  const {
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
  } = useClient()
  const { clearCart, totalItems } = useCart()
  const [customerAddress, setCustomerAddress] = useState('')
  const [isChangeAddressModalVisible, setIsChangeAddressModalVisible] = useState(false)
  const handleChange = (address) => {
    setCustomerAddress(address)
  }

  const handleSelect = async (address: string, placeId: string) => {
    const response = await googleMapsAutocompleteParser(placeId)
    const cartItems = totalItems()
    if (cartItems > 0) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined style={{ color: '#EB5757' }} />,
        title: `Tens a certeza que queres mudar a morada?`,
        content: `Ao mudar a morada todos os produtos no carrinho irão ser apagados`,
        okText: 'Não, cancelar',
        okType: 'primary',
        cancelText: 'Sim, mudar',
        cancelButtonProps: { style: { color: '#EB5757', border: 'none' } },
        className: 'change-address-modal',
        onOk() {
          setIsChangeAddressModalVisible(false)
        },
        onCancel() {
          changeClientAddressId(placeId)
          changeClientAddress(response.formatted_address)
          changeClientCity(response.city)
          changeClientDistrict(response.district)
          changeClientCountry(response.country)
          changeClientPostalCode(response.postal_code)
          changeClientCoordinates({
            latitude: response.lat.toString(),
            longitude: response.lng.toString(),
          })

          clearCart()
          setIsChangeAddressModalVisible(false)
        },
      })
    } else {
      changeClientAddressId(placeId)
      changeClientAddress(response.formatted_address)
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })

      clearCart()
      setIsChangeAddressModalVisible(false)
    }
  }

  if (!address) {
    return (
      <AddressInfoTopBarWithoutAddress direction="vertical">
        <AddressInfoCategoryTitle level={1}>{categoryTitle}</AddressInfoCategoryTitle>
        <AddressInfoCategorySmallDescription level={5}>
          Cerveja, vinho e licores entregues à tua porta
        </AddressInfoCategorySmallDescription>
        <Space size={0}>
          <MapsPlacesAutocomplete
            key={0}
            address={customerAddress}
            handleChange={handleChange}
            handleSelect={handleSelect}
          />
          <StyledBtn type="primary" size="large" icon={<ArrowRightOutlined />}></StyledBtn>
        </Space>
      </AddressInfoTopBarWithoutAddress>
    )
  }

  return (
    <>
      <StyledSpace direction="horizontal" align="center" size={0}>
        <StyledTextDelivery>Entrega</StyledTextDelivery>
        <StyledArrow />
        <StyledText type="link" size="small" onClick={() => setIsChangeAddressModalVisible(true)}>
          {address}
        </StyledText>
      </StyledSpace>
      <Modal
        visible={isChangeAddressModalVisible}
        title="Onde estás?"
        onCancel={() => setIsChangeAddressModalVisible(false)}
        footer={null}
        className="location-modal"
      >
        <MapsPlacesAutocomplete
          address={customerAddress}
          handleSelect={handleSelect}
          handleChange={handleChange}
        />
      </Modal>
    </>
  )
}

export default AddressInfoTopBar
