import AddressInfoTopBar from '@/components/shared/AddressInfoTopBar'
import PageLayout from '@/components/PageLayout'
import Link from 'next/link'
import Image from 'next/image'

const Custom404 = () => {
  return (
    <PageLayout>
      <AddressInfoTopBar />

      <div className="content-container erro-404-container">
        <Image
          alt="404"
          width={684}
          height={349}
          quality={100}
          className="image-404"
          src="/assets/images/404.jpg"
        />

        <h1>Estás perdido?</h1>
        <p>
          Não conseguiste encontrar o que procuravas?
          <br />
          Nós ajudamos!
        </p>
        <Link href="/">
          <a className="ant-btn ant-btn-primary">Voltar ao início</a>
        </Link>
      </div>
    </PageLayout>
  )
}

export default Custom404
